// router.js
import { createRouter, createWebHashHistory } from 'vue-router';
import privacyPolicy from './components/privacyPolicy.vue';
import HelloWorld from './components/HelloWorld.vue';
import servicePolicy from './components/servicePolicy.vue';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/', component: HelloWorld },
        { path: '/privacyPolicy', component: privacyPolicy },
        { path: '/servicePolicy', component: servicePolicy },
    ],
});

export default router;