import axios from "axios";
// import router from "@/router";
// axios.defaults.baseURL = ''  //正式
// axios.defaults.baseURL = '/api' //测试

// axios.defaults.baseURL = 'http://localhost:8088/healthmanage' //本地
// axios.defaults.baseURL = 'http://t.iynjk.com/healthmanage' //测试
axios.defaults.baseURL = 'https://y.iynjk.com/healthmanage' //生产

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时

axios.defaults.timeout = 150000;

axios.interceptors.request.use(config => {
    config.headers.setAuthorization(sessionStorage.getItem('token'))
    config.headers.set('id', sessionStorage.getItem('id'));
    config.headers.set('sourceType', 3);
    return config;
},
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(JSON.stringify(error))
        // if (error.response != undefined && error.response.data.respCode === '999999') {
        //     // router.push({ path: '/' });
        // } else {
        //     // console.log(JSON.stringify(error))
        //     // ElMessage({
        //     //     message: JSON.stringify(error),
        //     //     type: 'error'
        //     // })
        // }
        // return Promise.reject(new Error(JSON.stringify(error.message)));
        // alert(JSON.stringify(error), '请求异常', {
        //     confirmButtonText: '确定',
        //     callback: (action) => {
        //         console.log(action)
        //     }
        // });
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: data,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
        })
    },

    get(url, data) {
        axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    upload(url, data) {
        axios.defaults.headers.post["Content-Type"] = "multipart/form-data;charset=UTF-8";
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: data,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
        })
    },
    handleExport(url, data) {
        axios.defaults.headers.post["Content-Type"] = "multipart/form-data;charset=UTF-8";
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                data: data,
                responseType: 'blob',
                isPrompt: false,
            }).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    },
    fileDown (data, fileName) {
        let blob = new Blob([data]);
        let downloadElement = document.createElement("a");
        // 创建下载的链接
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName);
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
    },
    // 下载
    downloadFile(url, fileName) {
        const x = new XMLHttpRequest();
        x.open('GET', url, true);
        x.responseType = 'blob';
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response);
            const elink = document.createElement('a');
            elink.href = url;
            elink.target = '_self'; // 当前也 target打开新页面
            elink.setAttribute('download', fileName);
            elink.style.display = 'none';
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
        };
        x.send();
    }
}

// export default axios
