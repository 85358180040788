import { createApp } from 'vue'
import App from './App.vue'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import axios from '@/assets/axios.js'
import router from './router.js';
const app = createApp(App)
app.config.globalProperties.$axios = axios;

app.use(ViewUIPlus).use(router).mount('#app')
