<template>
  <div style="overflow: hidden">
    <Layout style="overflow-x: hidden;overflow-y: auto;">
      <Header
        class="layout-header"
        style="
          background-color: #f8f8f8;
          margin: 10px 0px 0px 0;
          text-align: left;
        "
        :style="wid > 1500 ? 'margin-left:150px' : 'margin-left:50px'"
      >
        <Form ref="formInline" inline>
          <FormItem>
            <img
              width="132"
              height="48"
              src="./../assets/img/logo.png"
              style="text-align: left"
            />
          </FormItem>
          <FormItem style="margin-top: 10px">
            <div
              class="item"
              :class="{ selected: tab == 1 }"
              @click="checked(1)"
            >
              首页
            </div>
            <div
              class="item"
              :class="{ selected: tab == 2 }"
              @click="checked(2)"
            >
              解决方案
            </div>
            <div
              class="item"
              :class="{ selected: tab == 3 }"
              @click="checked(3)"
            >
              专家系统
            </div>
            <div
              class="item"
              :class="{ selected: tab == 4 }"
              @click="checked(4)"
            >
              核心功能
            </div>
            <div
              class="item"
              :class="{ selected: tab == 5 }"
              @click="checked(5)"
            >
              关于颐年
            </div>
          </FormItem>
          <FormItem style="margin-top: 0px; margin-right: 10px">
            <div
              class="item"
              style="margin-top: 10px"
              :style="wid > 1500 ? 'margin-left:230px' : 'margin-left:80px'"
            >
              <img
                width="159"
                height="38"
                src="./../assets/img/telphone.png"
                style="text-align: left"
              />
            </div>
          </FormItem>
          <FormItem style="margin-left: 80px; text-align: center">
            <div class="butt">
              <div class="butt_content" @click="openLogin">登录</div>
            </div>
            <div class="butt" style="width: 96px; margin-left: 16px">
              <div
                class="butt_content seletd"
                style="width: 96px"
                @click="checked(1)"
              >
                免费试用
              </div>
            </div>
          </FormItem>
        </Form>
      </Header>

      <Content class="layout-content content_css" :style="{ 'min-height': het }">
        <div
          class="demo-scroll-top"
          ref="scroll"
          :style="{ height: het }"
          style="overflow-y: auto"
          @scroll="handleScroll"
        >
          <img v-if="active == 1" src="./../assets/img/index.png" />
          <img v-else src="./../assets/img/about.png" />
          <div class="footer_css" @click="openNewTab">Copyright © 2015-2021 连云港颐年健康科技有限公司 All Rights Reserved. 苏ICP备2023028439号</div>
          <div class="footer_css" style="margin-top: -20px;" @click="openNewTab1">Copyright © 2015-2021 连云港颐年健康科技有限公司 All Rights Reserved. 苏公网安备32070602010137号</div>
          <Footer class="layout-footer"></Footer>
        </div>
      </Content>
      <div
        v-if="active == 1 && tab == 1 && ret == 1 && showSticky == true"
        class="login_css"
        :style="wid > 1500 ? 'right:260px' : 'right:60px'"
      >
        <div class="tit">申请免费试用</div>
        <div class="login_info">
          <Login @on-submit="handleSubmit">
            <Mobile name="mobile" />
            <Captcha
              name="captcha"
              :field="['mobile']"
              @on-get-captcha="handleGetCaptcha"
            />
            <Input
              v-model="company"
              placeholder="姓名或者企业名称"
              size="large"
              style="margin-bottom: 32px"
            />
            <div style="margin-bottom: 32px; width: 43%; display: inline-block">
              <Checkbox v-model="border">我已阅读并同意</Checkbox>
            </div>
            <div style="margin-bottom: 32px; width: 53%; display: inline-block">
              <a @click="toService">《服务协议》</a>
              <a @click="toPrivacy">《隐私政策》</a>
            </div>

            <Submit
              style="
                border-radius: 24px;
                width: 216px;
                height: 48px;
                background-color: #537ff2;
                text-align: center;
                margin-left: 35px;
              "
              >立即提交</Submit
            >
          </Login>
        </div>
      </div>
      <div
        v-if="active == 1 && tab == 1 && ret == 2"
        class="login_css"
        :style="wid > 1500 ? 'right:260px' : 'right:60px'"
      >
        <div class="tit">申请成功</div>
        <div style="margin: 40px 0">
          <img v-if="active == 1" src="./../assets/img/success.png" />
        </div>
        <div class="tit_mid">恭喜您申请成功！</div>
        <div class="tit_bot">工作人员会尽快联系您</div>
      </div>
    </Layout>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";

import { useRouter } from 'vue-router';
const router = useRouter();
// const formValidate = {
//   telphone: "",
//   code: "",
// };
// const ruleValidate = {
//   telphone: [
//     { required: true, message: "请填写正确的手机号", trigger: "blur" },
//   ],
//   code: [{ required: true, message: "验证码错误", trigger: "blur" }],
// };
const company = ref();
const border = ref(false);
const ret = ref(1);
const active = ref(1);
const tab = ref(1);
const het = window.innerHeight + "px";
const wid = window.innerWidth;
const { proxy } = getCurrentInstance();
function checked(type) {
  tab.value = type;
  var to = 0;
  if (type == 2) {
    to = 550;
  }
  if (type == 3) {
    to = 1800;
  }
  if (type == 4) {
    to = 2700;
  }

  if (type == 5) {
    active.value = 2;
  } else {
    active.value = 1;
  }

  proxy.$ScrollTop(proxy.$refs.scroll, {
    to: to,
    time: 1000,
  });
}

function handleSubmit(valid, { mobile, captcha }) {
  if (!border.value) {
    proxy.$Message.warning({
      content: "请勾选协议",
      duration: 2,
    });
  }
  if (!valid) {
    this.$Modal.info({
      title: "输入的内容如下：",
      content: "mobile: " + mobile + " | captcha: " + captcha,
    });
  }

  var param = {
    telephone: mobile,
    code: captcha,
    company: company.value,
  };
  proxy.$axios
    .post("/intendedUserInfo/add", param)
    .then((res) => {
      if (res.data.respCode == "000000") {
        proxy.$Message.success({
          content: "提交成功",
          duration: 2,
        });
        ret.value = 2;
      } else {
        proxy.$Message.error({
          content: res.data.respMsg,
          duration: 2,
        });
      }
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
    });
}

function handleGetCaptcha(valid, { mobile }) {
  var param = {
    telephone: mobile,
  };
  proxy.$axios
    .get("/message/send", param)
    .then((res) => {
      if (res.data.respCode == "000000") {
        proxy.$Message.success({
          content: "发送成功",
          duration: 2,
        });
      } else {
        proxy.$Message.error({
          content: res.data.respMsg,
          duration: 2,
        });
      }
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
    });
}
const showSticky = ref(true);

const handleScroll = () => {
  const currentScroll = event.target.scrollTop;
  if (Number(currentScroll) > 200) {
    showSticky.value = false;
  } else {
    showSticky.value = true;
  }
};

const openLogin = () => {
  const url = "https://y.iynjk.com"; // 替换为你想要访问的网站
  window.open(url, "_blank");
};
const openNewTab = () => {
  const url = "https://beian.miit.gov.cn/#/home"; // 替换为你想要访问的网站
  window.open(url, "_blank");
};
const openNewTab1 = () => {
  const url = "https://beian.mps.gov.cn/#/query/webSearch"; 
  window.open(url, "_blank");
};

const toPrivacy = () =>{
   router.push("/privacyPolicy")
  // router.push({ path: '/privacyPolicy' })
}
const toService = () =>{
   router.push("/servicePolicy")
  // router.push({ path: '/privacyPolicy' })
}
onMounted(() => {});

// const { proxy } = getCurrentInstance();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item {
  width: 100px;
  height: 38px;
  display: inline-block;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 16px;
  color: #2d3038;
  line-height: 38px;
  text-align: center;
  font-style: normal;
}
.selected {
  font-weight: 600;
  font-size: 16px;
  color: #537ff2;
  font-style: normal;
}

.butt {
  display: inline-block;
  text-align: center;
  width: 64px;
  height: 38px;
  line-height: 38px;
  border-radius: 18px;
  border: 1px solid #537ff2;
  margin-top: 10px;
}
.butt_content {
  width: 64px;
  height: 38px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 16px;
  color: #537ff2;
  line-height: 38px;
  font-style: normal;
  border-radius: 18px;
}
.seletd {
  background-color: #537ff2;
  color: #ffffff;
  border-radius: 18px;
}
.content_css {
  min-height: 600px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f8f8f8;
  overflow: hidden;
}
.login_css {
  position: absolute;
  display: block;
  float: right;
  z-index: 99;
  width: 386px;
  height: 442px;
  background: #f6f9ff;
  border-radius: 16px;
  top: 110px;
  right: 260px;
  text-align: center;
}
.tit {
  width: 100%;
  height: 33px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 24px;
  color: #2d3038;
  line-height: 33px;
  text-align: center;
  font-style: normal;
  margin-top: 32px;
}
.login_info {
  width: 350px;
  margin-top: 32px;
  padding-left: 30px;
  text-align: center;
}
.tit_bot {
  width: 386px;
  height: 22px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 16px;
  color: #2d3038;
  line-height: 22px;
  text-align: center;
  font-style: normal;
}
.tit_mid {
  width: 386px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #2d3038;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin-bottom: 25px;
}
.btn {
  width: 216px;
  height: 48px;
  background: #537ff2;
  box-shadow: 0px 2px 6px 0px rgba(0, 70, 255, 0.32);
  border-radius: 24px;
}
.footer_css{
  display: block;
  width:100%;
  text-align: center;
  z-index: 999999;
  height:50px;
  margin-top:-150px;
  color: #fff;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 1px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #f6f6f6;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 0;
}
</style>
